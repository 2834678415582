import packageJson from './package.json';

// Environment variables
export enum Environments {
  PRODUCTION = 'production',
  DEV = 'dev',
  INTEGRATION = 'integration',
}

export const UTF8_BOM = '\u{FEFF}';

export const PICTARINE_VERSION_CODE = parseInt(
  packageJson.version.split('.')[2] || '0',
  10,
);

export const FEES_DISPLAY_NAME = 'Service';

// Page Headers (can be overridden by .env file)
export const APP_TITLE = `Print your memories at ${process.env.NEXT_PUBLIC_PARTNER_DISPLAY_NAME} Photos by Picta`;
export const APP_DESCRIPTION = `Upload your favorite memories and Print your Photos on Prints and Posters. Enjoy a 1 hour Pickup at ${process.env.NEXT_PUBLIC_PARTNER_DISPLAY_NAME} with Picta.`;

// URL
export const URL_PARAM_BRAND = 'brand';

export const REGEX_STATUS_410 =
  /.*?\/(?:template|prison|stores|author|pardon-.*|terms-and-conditions-puzzle\/?)|(?:about-picta|picta-.*-program|wording|our-mission)/gm;

// Cookie keys
export const COOKIE_DEVICE_ID = 'pictarine-deviceId';
export const COOKIE_ALREADY_USER = 'alreadyUser';
export const COOKIE_AB_FEATURES_OVERRIDE = 'ab-features-override';

// Local storage
export const LOCAL_CART_KEY = 'cart';
export const LOCAL_ORDER_KEY = 'order';

export const LOCAL_LOGS_KEY = 'appLogs';
export const LOCAL_TAG_KEY = 'tagVersion';
export const LOCAL_SEO_ORDER_TOTAL_PRICE = 'seo_lastOrderTotalPrice';

export const LOCAL_PRODUCTS_LIKED = 'productsLiked';
export const LOCAL_CUSTOMIZED_PRODUCTS = 'customizedProduct';
// Session Storage
export const SESSION_STORAGE_CROP_ANIMATION_VIEWED = 'cropAnimationViewed';

// A/B TESTING
export const AB_TEST_COUPONS = 'coupons';
export const AB_PREPAID = 'prepaid';
export const AB_SMARTLOOK = 'screen_recording';
export const AB_HOMEPAGE_CARDS = 'homepage_cards';
export const AB_HOMEPAGE_NEW_HEADER = 'homepage_new_header';
export const AB_HOMEPAGE_WALL_ARTS = 'homepage_wallArts';
export const AB_MULTICROPPER = 'multi_cropper';
export const AB_MULTIPARTNER = 'multi_partner';

export const PRINT_ID = 'print';
export const MEMORY_CACHE_TIME = 60 * 60 * 30; // 30 minutes
export const MEMORY_CACHE_PRODUCTS_AND_GROUPS_KEY = 'productsAndGroups';

// To Reduce file size on bucket

// Logs
export const INTERVAL_TIME_LOGS = 2000;
export const PERCENTAGE_VISIBLE_TO_SEND_LOG = 0.8;

export const TERMS_USE_URL = 'termsOfUseURL';
export const PRIVACY_POLICY_URL = 'privacyPolicyURL';
export const MAX_CART_PRICE = 'maxCartPrice';
export const MAX_CART_ITEMS = 'maxCartItems';
export const DISPLAY_MAX_CART_ITEMS_ICON = 99;

// Images
export const ACCEPTED_IMAGE_FORMAT = ['image/png', 'image/jpeg', 'image/jpg'];

// Cart && products

export const DEFAULT_PRODUCT_QUANTITY = 1;

export const LOTTIE_DEFAULT_OPTIONS = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export enum HTTPStatus {
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  CONFLICT = 409,
  ERROR_API = 500,
  OK = 200,
}

// API
export const DEFAULT_REVALIDATE_TIME = 15 * 60; // 15 minutes

// HEADERS
export const PICTARINE_API_VERSION_HEADER = 'pictarine-apiVersion';

export const LANDING_PAGES_PATH = ['/gifts/valentines-day', '/events/wedding'];
export const DESKTOP_MEDIA_QUERY = '(min-width: 1024px)';
export const MOBILE_MEDIA_QUERY = '(max-width: 600px)';

export const BOT_DEVICE_ID = '00000000-0000-0000-0000-00000000Ym90';
